<template>
  <div>
    <div class="mb-2 row">
      <div class="mx-3">
        <label for="">Fecha Inicio</label>
        <app-datepicker v-model="ini_schedule"></app-datepicker>
      </div>
      <div>
        <label for="">Fecha Fin</label>
        <app-datepicker v-model="end_schedule"></app-datepicker>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th></th>
            <th>DOMINGO</th>
            <th>LUNES</th>
            <th>MARTES</th>
            <th>MIERCOLES</th>
            <th>JUEVES</th>
            <th>VIERNES</th>
            <th>SABADO</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colspan="8" class="text-center">
              Primer Turno
            </th>
          </tr>
          <tr>
            <th class="p-0">
              <ul class="list-group">
                <li class="list-group-item rounded-0">INICIO</li>
                <li class="list-group-item rounded-0">FIN</li>
              </ul>
            </th>
            <td class="p-0" v-for="(l, i) in Array(7)" :key="i">
              <tbtm
                :dayOfWeek="i"
                :schedule_id="id"
                :hourToBegin="hourToBegin"
                :disabled="days_of_week[i]"
                :group="1"
                :interval="interval"
                :ref="`g_1_${i}`"
              ></tbtm>
              <!-- 
                :ini_schedule="date_begin"
                :end_schedule="date_end"
                :employee_id="employee_id" -->
            </td>
          </tr>
          <tr>
            <th colspan="8" class="text-center">
              Segundo Turno
            </th>
          </tr>
          <tr>
            <th class="p-0">
              <ul class="list-group">
                <li class="list-group-item rounded-0">INICIO</li>
                <li class="list-group-item rounded-0">FIN</li>
              </ul>
            </th>
            <td class="p-0" v-for="(l, i) in Array(7)" :key="i">
              <tbtm
                :dayOfWeek="i"
                :schedule_id="id"
                :hourToBegin="hourToBegin"
                :disabled="days_of_week[i]"
                :group="2"
                :interval="interval"
                :ref="`g_2_${i}`"
              ></tbtm>
              <!-- :ini_schedule="date_begin"
                :end_schedule="date_end"
                :employee_id="employee_id" -->
            </td>
          </tr>
          <tr>
            <th>Deshabilitar</th>
            <td class="text-center" v-for="(l, i) in Array(7)" :key="i">
              <app-checkbox
                placeholder=""
                v-model="days_of_week[i]"
              ></app-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <app-button-submit @click="save()" :text=" id ? 'Actualizar' : 'Guardar' "></app-button-submit>
  </div>
</template>
<script>
import TimeSchedule from "./time-schedule";
// import { DentalClinicService } from '../../../dental-clinic-module/service';
import { AdminService } from "../../AdminService";

export default {
  components: {
    tbtm: TimeSchedule
  },

  // directives
  // filters

  props: {
    hourToBegin: { required: true, type: Number }
  },

  data: () => ({
    id: null,
    ini_schedule: null,
    end_schedule: null,
    interval: null,
    employee_id: null,
    // days_of_week: Array.from(Array(7), () => true)
    days_of_week: [true, true, true, true, true, true, true]
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    reset() {
      this.id = null;
      this.ini_schedule = null;
      this.end_schedule = null;
      // this.interval = null;
      // this.employee_id = null;
      this.days_of_week = [true, true, true, true, true, true, true];
      for (let i = 0; i < 7; i++) {
        this.$refs[`g_1_${i}`][0].reset();
      }
      for (let i = 0; i < 7; i++) {
        this.$refs[`g_2_${i}`][0].reset();
      }
    },
    setValue(item) {
      this.load(item);
    },
    load(sch) {
      this.id = sch.id;
      this.ini_schedule = sch.ini_schedule;
      this.end_schedule = sch.end_schedule;
      // this.employee_id = sch.employee_id;
      this.days_of_week = [
        !sch.d0,
        !sch.d1,
        !sch.d2,
        !sch.d3,
        !sch.d4,
        !sch.d5,
        !sch.d6
      ];

      // grupo 1
      sch.times
        .filter(x => x.group == 1)
        .map(x => {
          // console.log();
          this.$refs["g_1_" + x.day_of_week][0].load(x);
        });
      sch.times
        .filter(x => x.group == 2)
        .map(x => {
          this.$refs["g_2_" + x.day_of_week][0].load(x);
        });
    },
    save() {
      // grupo 1
      var times = [];
      for (let i = 0; i < 7; i++) {
        // console.log(this.$refs[`g_1_${i}`][0].getData());
        times.push(this.$refs[`g_1_${i}`][0].getData());
      }
      for (let i = 0; i < 7; i++) {
        times.push(this.$refs[`g_2_${i}`][0].getData());
      }
      // console.log(times);
      // return;
      AdminService.saveEmployeeSchedule({
        id: this.id,
        ini_schedule: this.ini_schedule,
        end_schedule: this.end_schedule,
        employee_id: this.employee_id,
        d0: !this.days_of_week[0],
        d1: !this.days_of_week[1],
        d2: !this.days_of_week[2],
        d3: !this.days_of_week[3],
        d4: !this.days_of_week[4],
        d5: !this.days_of_week[5],
        d6: !this.days_of_week[6],
        times
      }).then(
        res => {
          this.id = res;
          this.$emit("submitted", res);
        },
        err => {
          this.$store.dispatch("showError", err.response.data.message);
        }
      );
    },
    loadToEmployee({ interval_service, employee_id }) {
      // console.log(arguments);
      this.reset();
      this.interval = interval_service;
      this.employee_id = employee_id;
    }
  }
};
</script>

<style scoped>
/* .custom-select {
  border-radius: 0px;
} */
</style>
