<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-01-12 17:19 -->
<!-- Description:  -->
<template>
  <div>
    ScheduleManager {{ employeeId }}
    <div>
      <button
        class="btn btn-primary"
        @click="
          $refs.elSchedule.reset();
          $refs.elModalForm.show();
        "
      >
        Nuevo
      </button>
    </div>
    <table class="table table-bordered table-sm">
      <thead>
        <tr>
          <th>ID</th>
          <th>INICIO</th>
          <th>FIN</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>{{ l.id }}</td>
          <td>{{ l.ini_schedule }}</td>
          <td>{{ l.end_schedule }}</td>
          <td>
            <button
              class="btn btn-light btn-sm"
              @click="
                $refs.elSchedule.setValue(l);
                $refs.elModalForm.show();
              "
            >
              <i class="fas fa-edit"></i>
            </button>
            <button class="btn btn-light btn-sm" @click="confirmDelete(l.id)">
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <app-modal-basic ref="elModalForm" :xl="true">
      <Form ref="elSchedule" :hourToBegin="hourToBegin"></Form>
    </app-modal-basic>
  </div>
</template>
<script>
import { AdminService } from "src/admin-module/AdminService.js";
//import ScheduleForm from "./ScheduleForm.vue";
import Schedule from "../../admin-module/employees/schedule/schedule.vue";

// script content
export default {
  name: "ScheduleManager",
  components: {
    Form: Schedule,
  },

  // directives

  props: {
    //
  },

  data: () => ({
    employeeId: null,
    interval: 0,
    list: [],
  }),

  computed: {
    hourToBegin() {
      return 9;
    },
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    setEmployeeConfig(reg) {
      this.employeeId = reg.id;
      this.getList();
      this.$refs.elSchedule.loadToEmployee({
        interval_service: reg.interval_service,
        employee_id: this.employeeId,
      });
    },
    async confirmDelete(id) {
      if(window.confirm("seguro(a) de eliminar ese horario?")) {
        await AdminService.deleteEmployeeSchedule(id);
        this.getList();
      }
    },
    async getList() {
      this.list = [];
      var res = await AdminService.getEmployeeSchedules({
        employee_id: this.employeeId,
        page_size: 999,
      });
      this.list = res.data;
    },
    //
    //
  },
};
</script>
<style scoped></style>
