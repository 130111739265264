<!-- Author: Wilson Pilco Nunez -->
<!--  Email: wilsonaux1@gmail.com -->
<!--  Created at: 2023-05-05 19:25 -->
<!--  Description:  -->
<template>
  <div>
    <h4>DOCTORES</h4>
    <app-table-registers ref="tr" :getList="getList">
      <template slot="table">
        <thead>
          <tr>
            <th>U. COD</th>
            <th>NOMBRE</th>
            <th>PORCENTAJE(%)</th>
            <th>INTERVALO DE ATENCION MIN</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list.data" :key="l.id">
            <td>{{ l.id }}</td>
            <td>{{ l.employee_name }}</td>
            <td>{{ (l.portion_by_service / 100) | percent }}</td>
            <td>{{ l.interval_service }}min</td>
            <td>
              <!-- <button
                class="btn btn-light btn-sm"
                @click="$refs.dEmpPays.show(); $refs.payments.setEmployeeId(l.id)"
              >
                <i class="fa fa-book"></i>
              </button> -->
              <button
                class="btn btn-light btn-sm"
                @click="
                  $refs.dEmpHor.show();
                  $refs.elScheduleManager.setEmployeeConfig(l)
                "
              >
                <i class="fa fa-calendar"></i>
              </button>
              <button
                class="btn btn-sm btn-light"
                @click="
                  $refs.dConf.show();
                  $refs.formConf.reset();
                  $refs.formConf.loadReg(l);
                "
              >
                <i class="fa fa-cog"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="dConf">
      <FormConfig
        ref="formConf"
        @submitted="
          $refs.dConf.hide();
          $refs.tr.gl();
        "
      ></FormConfig>
    </app-modal-basic>

    <app-modal-basic ref="dEmpHor" :xl="true">
      <h4>HORARIO</h4>
      <ScheduleManager ref="elScheduleManager"></ScheduleManager>
    <!--   <list-schedules -->
    <!--     ref="listsch" -->
    <!--     @new="$refs.sch.reset()" -->
    <!--     @load="$refs.sch.load($event)" -->
    <!--     :employee_id="employee_id" -->
    <!--   ></list-schedules> -->
    <!--   <schedule -->
    <!--     @submitted="$refs.listsch.reload()" -->
    <!--     :hourToBegin="hourToBegin" -->
    <!--     ref="sch" -->
    <!--   ></schedule> -->
    <!--   [> <Horary ref="horary" :employeeId="employee_id"></Horary> <] -->
    </app-modal-basic>
  </div>
</template>
<script>
// script content
import { DentalClinicService } from "../service";
// import schedule from "../../admin-module/employees/schedule/schedule";
// import listSchedules from "../../admin-module/employees/schedule/list";
// import { mapState } from "vuex";
import ScheduleManager from "./ScheduleManager.vue";
import FormConfig from "../liquidations/Form.vue";
export default {
  name: "DoctorList",
  components: {
    FormConfig,
    ScheduleManager,
  },

  // directives

  props: {
    //
  },

  data: () => ({
    list: {},
    employee_id: undefined,
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    getList(params) {
      return new Promise((rsv) => {
        DentalClinicService.getEmployeeConfigs(params).then((res) => {
          res.data = res.data.map((x) => ({
            ...x,
            portion_by_service: x.portion_by_service ? x.portion_by_service : 0,
            interval_service: x.interval_service ? x.interval_service : 0,
          }));
          this.list = res;
          rsv(res);
        });
      });
    },
  },
};
</script>
<style scoped></style>
