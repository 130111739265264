<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>COLABORADOR</label>
      <SelectEmployee :disabled="true" v-model="employee_id" ref="selEmp"></SelectEmployee>
    </div>
    <div class="form-group">
      <label for>PORCENTAJE ODONTOLOGIA</label>
      <app-input-number v-model="portion_by_service"></app-input-number>
    </div>

    <!-- <div class="form-group"> -->
    <!--   <label for>PORCENTAJE PERIODONCIA</label> -->
    <!--   <app-input-number v-model="portion_by_service1"></app-input-number> -->
    <!-- </div> -->

    <!-- <div class="form-group"> -->
    <!--   <label for>PORCENTAJE PEDIATRIA</label> -->
    <!--   <app-input-number v-model="portion_by_service2"></app-input-number> -->
    <!-- </div> -->

    <!-- <div class="form-group"> -->
    <!--   <label for>PORCENTAJE IMPLANTES</label> -->
    <!--   <app-input-number v-model="portion_by_service3"></app-input-number> -->
    <!-- </div> -->

    <div class="form-group">
      <label for>TIEMPO ATENCION MINUTOS</label>
      <!-- <app-input-number v-model="interval_service"></app-input-number> -->
      <select-interval v-model="interval_service"></select-interval>
    </div>
    <app-button-submit></app-button-submit>
  </form>
</template>

<script>
import SelectEmployee from "../../admin-module/employees/Select";
import {DentalClinicService} from "../service";
import SelectInterval from "../../admin-module/employees/schedule/select-interval";

export default {
  components: {
    SelectEmployee,
    SelectInterval
  },
  data: () => ({
    employee_id: null,
    portion_by_service: null,
    portion_by_service1: 0,
    portion_by_service2: 0,
    portion_by_service3: 0,
    interval_service: null
  }),
  methods: {
    loadReg(reg) {
      this.employee_id = reg.id;
      this.portion_by_service = reg.portion_by_service;
      this.interval_service = reg.interval_service;
      this.$refs.selEmp.setValueFromId(reg.id);
    },
    save() {
      DentalClinicService.saveEmployeeConfig(this.$data).then(() =>
        this.$emit("submitted")
      );
    },
    reset() {
      this.employee_id = null
      this.portion_by_service = null
      this.portion_by_service1 = 0
      this.portion_by_service2 = 0
      this.portion_by_service3 = 0
      this.interval_service = null
    }
  }
};
</script>

<style>
</style>
