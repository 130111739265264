<template>
  <ul class="list-group">
    <li class="list-group-item rounded-0 p-0">
      <app-select-time
        v-model="ini_time"
        class="app_tm_sc_cs rounded-0 border-0 text-center"
        :afterHour="hourToBegin"
        :interval="interval"
        :disabled="disabled"
      ></app-select-time>
    </li>
    <li class="list-group-item rounded-0 p-0">
      <app-select-time
        v-model="end_time"
        class="app_tm_sc_cs rounded-0 border-0 text-center"
        :afterHour="hourToBegin"
        :interval="interval"
        :disabled="disabled"
      ></app-select-time>
    </li>
  </ul>
</template>
<script>
// import { AdminService } from "../../AdminService";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    disabled: {
      default: true
    },
    // ini_schedule: {},
    // end_schedule: {},
    dayOfWeek: {},
    schedule_id: {},
    hourToBegin: { default: 0 },
    interval: { default: 1 },
    group: {}
    // employee_id: {}
  },

  data: () => ({
    ini_time: null,
    end_time: null
  }),

  computed: {
    //
  },

  watch: {
    // schedule_id() {
    //   console.log("traer horario");
    // }
    // timeToInit() {
    //   this.save();
    // },
    // timeToEnd() {
    //   this.save();
    // }
  },

  mounted() {
    //
  },

  methods: {
    reset() {
      this.ini_time = null;
      this.end_time = null;
    },
    // save() {
    //   var data = {
    //     schedule_id: this.schedule_id,
    //     // ini_schedule: this.ini_schedule,
    //     // end_schedule: this.end_schedule,
    //     day_of_week: this.dayOfWeek,
    //     ini_time: this.ini_time,
    //     end_time: this.end_time,
    //     // employee_id: this.employee_id,
    //     group: this.group
    //   };
    //   data[`d${this.dayOfWeek}`] = this.dayOfWeek;
    //   AdminService.saveScheduleTime(data);
    // }
    load(x) {
      this.reset();
      // this.schedule_id =
      // this.dayOfWeek = x.day_of_week;
      this.ini_time = x.ini_time;
      this.end_time = x.end_time;
      // this.group = x.group;
    },

    getData() {
      return {
        // schedule_id: this.schedule_id,
        day_of_week: this.dayOfWeek,
        ini_time: this.ini_time,
        end_time: this.end_time,
        group: this.group
      };
    }
  }
};
</script>

<style>
.app_tm_sc_cs {
  height: 3rem;
}
</style>
