<template>
  <select class="custom-select" v-model="childValue">
    <option :value="5">5 minutos</option>
    <option :value="10">10 minutos</option>
    <option :value="15">15 minutos</option>
    <option :value="20">20 minutos</option>
    <option :value="30">30 minutos</option>
    <option :value="60">60 minutos</option>
  </select>
</template>
<script>
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    value: {}
  },

  data: () => ({
    //
  }),

  computed: {
    childValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
